import React, { useEffect, useMemo } from 'react';

import ReactPreview from './ReactPreview';

import { LuCode2, LuPencilLine } from 'react-icons/lu';
import { VscLayoutPanelOff } from 'react-icons/vsc';

import FacebookCard from './LinkPreviewCards/facebook';
import XCard from './LinkPreviewCards/x';
import LinkedInCard from './LinkPreviewCards/linkedin';

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';

import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import {
  dismissMissingTagsAlert,
  generateAiMetaData,
  handleEditMetaData, handleEditMetaDataFromResults,
  makeLinkPreviewCall,
  setActiveTab,
  setLinkPreviewUrl, toggleMarketingSignUpModal,
  dismissErrorAlert
} from '../../../../redux/actions/linkPreview';
import MarketingPaidModal from '../../../../shared/components/Modals/MarketingPaidModal';
import { BsStars } from 'react-icons/bs';

import './link-preview-style.css';


const LinkPreviewTool = (props) => {
  //State Props Below
  const { formData, results, showSignUpModal, updatedResults, metaTags, activeTab, loading, seoSuggestions, error } = props;
  //Dispatch Props Below
  const { handleEditMetaData, setActiveTab, setLinkPreviewUrl, makeLinkPreviewCall, toggleMarketingSignUpModal, handleEditMetaDataFromResults, generateAiResponse, dismissMissingTagsAlert, dismissErrorAlert } = props;

  const [copied, setCopied] = React.useState(false);

  const [ aiModalVisible, setAiModalVisible ] = React.useState(false);
  const [ showLoadingInputs, setShowLoadingInputs ] = React.useState(false);

  useEffect(() => {
    if(seoSuggestions.loading) {
      setShowLoadingInputs(true);
    } else {
      setShowLoadingInputs(false);
    }
  }, [seoSuggestions.loading]);

  const handleChange = (e) => {
    const { value } = e.target;

    let newUrl = value;
    if(newUrl && newUrl.length > 4 && newUrl.substring(0, 4) !== 'http') {
      newUrl = 'http://' + newUrl;
    }

    setLinkPreviewUrl(newUrl);
  };

  // Function to copy the generated HTML Meta Tags to the clipboard
  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(metaTags);

      // Set the copied state to true temporarily
      setCopied(true);

      setTimeout(() => {
        setCopied(false);
      }, 2000);
    } catch (err) {
      alert('Failed to copy text to clipboard');
    }
  };

  const handlePreviewCardTabChange = (tab) => {
    setActiveTab(tab);
  };

  const toggleSignUpModal = () => {
    toggleMarketingSignUpModal(false);
  }

  const toggleAiModal = () => {
    setAiModalVisible(!aiModalVisible);
  }

  const handleAiModalMetaEdit = (name, value) => {
    handleEditMetaDataFromResults(name, value);
  }

  const handleGenerateAiClick = async () => {
    if(formData.url) {
      generateAiResponse();
    }
  };

  return (
    <div style={{ position: results ? 'unset' : 'relative', padding: '5px'}} className={'link-preview-container'}>
      <MarketingPaidModal visible={showSignUpModal} toggleModal={toggleSignUpModal} />
      { error ? <div className="link-preview-no-tag-alert">
        <p>{error}</p>
        <span className="dismiss-alert" onClick={dismissErrorAlert}>×</span>
      </div> : null}
      {/*<AiModal handleInformationEdit={(name, value) => handleAiModalMetaEdit(name, value)} visible={aiModalVisible} toggle={toggleAiModal} currentSeo={metaTags}  />*/}
      <div>
        <form className={'url_preview-form'} onSubmit={makeLinkPreviewCall}>
          <input
            className={'url_preview-form-input'}
            type="url"
            id="url"
            name="url"
            placeholder={'http://somesite.com'}
            value={formData.url}
            onChange={handleChange}
            required
          />
          {loading ? <div style={{ display: 'flex', height: '200px', width: '130px', justifyContent: 'center', alignItems: 'center' }}>
            <div className="spinner-border text-success centerText" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div> : (
            <Button style={{ marginBottom: 0}} className="btn btn-primary account__btn account__btn--small whiteText">Check URL</Button>
          )}
        </form>
        { seoSuggestions.showMissingTagsAlert ? <div className="link-preview-no-tag-alert">
          <p>It seems this page may be missing OG tags. We've inferred them based on its content.</p>
          <span className="dismiss-alert" onClick={dismissMissingTagsAlert}>×</span>
        </div> : null}
      </div>
      <div>
        <div className={`react_component_preview-container ${results ? 'show' : ''}`}>
          <div className={'react_component_preview-section'}>
            <div className={'react_component_preview-section_inner-container'}>
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            <h3>Edit</h3>
                            <LuPencilLine size={18} style={{ margin: 'auto 15px' }}/>
                        </span>
              <p style={{ marginTop: 0, }}>Customize how your content appears on search engines and social
                platforms. Modify the title, description, and image to optimize visibility and
                engagement.</p>
              <form className={'meta-tag-generator-form'}>
                <label htmlFor={'title'}>Title</label>
                {showLoadingInputs ? (
                  <div className="input-loading-placeholder" />
                ) : (
                  <textarea
                  id="title"
                  name="title"
                  placeholder={'Title'}
                  value={formData.title}
                  onChange={handleEditMetaData}
                /> ) }
                <p style={{ color: 'rgba(160, 171, 187, 1)', margin: '-5px auto 15px 2px' }}><strong>Recommended</strong>: 60 Characters</p>
                <label htmlFor={'description'}>Description</label>
                {showLoadingInputs ? (
                  <div className="input-loading-placeholder" />
                ) : (
                <textarea
                  id="description"
                  name="description"
                  placeholder={'Description'}
                  value={formData.description}
                  onChange={handleEditMetaData}
                /> ) }
                <p style={{ color: 'rgba(160, 171, 187, 1)', margin: '-5px auto 15px 2px' }}><strong>Recommended</strong>: 155 - 160 Characters</p>
                <label htmlFor={'image'}>Image URL</label>
                <input
                  type="text"
                  id="image[url]"
                  name="image"
                  placeholder={'Image URL'}
                  value={formData.image}
                  onChange={handleEditMetaData}
                />
              </form>
              <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                <div className={`btn-purple ${formData.url ? '' : 'disabled'}`} onClick={handleGenerateAiClick}><BsStars />Optimize with AI</div>
                </span>
            </div>
          </div>
          <div className={'react_component_preview-section'}>
            <div className={'react_component_preview-section_inner-container'} >
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            <h3>Preview</h3>
                            <VscLayoutPanelOff size={18} style={{ margin: 'auto 15px' }}/>
                        </span>
              <div className={'react_component_preview-preview-tabs'}>
                <p className={`react_component_preview-tab ${activeTab === 'facebook' ? 'active' : null}`} onClick={() => handlePreviewCardTabChange('facebook')}>Facebook</p>
                <p className={`react_component_preview-tab ${activeTab === 'linkedIn' ? 'active' : null}`} onClick={() => handlePreviewCardTabChange('linkedIn')}>LinkedIn</p>
                <p className={`react_component_preview-tab ${activeTab === 'x' ? 'active' : null}`} onClick={() => handlePreviewCardTabChange('x')}>X | Twitter</p>
              </div>
              <span className={`react_component_preview-tab-card ${activeTab === 'facebook' ? 'active' : null}`}>
                                    <FacebookCard FacebookPreview={<ReactPreview cardComponent={'facebook'} newResults={updatedResults} results={results} loading={loading}/> } />
                                </span>
              <span className={`react_component_preview-tab-card ${activeTab === 'linkedIn' ? 'active' : null}`}>
                                    <LinkedInCard LinkedInPreview={<ReactPreview cardComponent={'linkedin'} newResults={updatedResults} results={results} loading={loading}/> } />
                                </span>
              <span className={`react_component_preview-tab-card ${activeTab === 'x' ? 'active' : null}`}>
                                    <XCard XPreview={<ReactPreview cardComponent={'x'} newResults={updatedResults} results={results} loading={loading}/> } />
                                </span>
            </div>
          </div>
          <div className={'react_component_preview-section'}>
            <div style={{ padding: '20px'}}>
                        <span style={{ maxHeight: '40px', display: 'flex', alignItems: 'center' }}>
                            <h3>Copy</h3>
                            <LuCode2 size={24} style={{ margin: 'auto 15px' }}/>
                        </span>
              <SyntaxHighlighter showLineNumbers style={tomorrow} language={'html'} >
                {(`<!-- HTML Meta Tags -->
<title>${metaTags.title || ''}</title>
<meta name="description" content="${metaTags.description || ''}">

<!-- Facebook Meta Tags -->
<meta property="og:url" content="${metaTags.url || ''}">
<meta property="og:type" content="${metaTags.type || ''}">
<meta property="og:title" content="${metaTags.title || ''}">
<meta property="og:description" content="${metaTags.description || ''}">
<meta property="og:image" content="${metaTags.image || ''}">

<!-- Twitter Meta Tags -->
<meta property="twitter:url" content="${metaTags.url || ''}">
<meta name="twitter:title" content="${metaTags.title || ''}">
<meta name="twitter:description" content="${metaTags.description || ''}">
<meta name="twitter:image" content="${metaTags.image || ''}">

<!-- Meta Tags Generated via https://www.opengraph.io -->`)}
              </SyntaxHighlighter>
              <Button style={{ marginBottom: 0}} className="btn btn-primary account__btn account__btn--small whiteText" onClick={handleCopyToClipboard} type="submit">{copied ? 'Copied' : 'Copy HTML Meta Tags'}</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  formData: state.linkPreview.form,
  results: state.linkPreview.results,
  newResults: state.linkPreview.newResults,
  updatedResults: state.linkPreview.updatedResults,
  isSubmitting: state.linkPreview.isSubmitting,
  error: state.linkPreview.error,
  success: state.linkPreview.success,
  loading: state.linkPreview.loading,
  showCaptcha: state.linkPreview.showCaptcha,
  metaTags: state.linkPreview.metaTags,
  activeTab: state.linkPreview.activeTab,
  showSignUpModal: state.linkPreview.showSignUpModal,
  seoSuggestions: state.linkPreview
});

const mapDispatchToProps = (dispatch) => ({
  handleEditMetaData: (e) => dispatch(handleEditMetaData(e)),
  handleEditMetaDataFromResults: (name, value) => dispatch(handleEditMetaDataFromResults(name, value)),
  generateAiResponse: () => dispatch(generateAiMetaData()),
  setLinkPreviewUrl: (url) => dispatch(setLinkPreviewUrl(url)),
  setActiveTab: (tab) => dispatch(setActiveTab(tab)),
  makeLinkPreviewCall: (e) => dispatch(makeLinkPreviewCall(e)),
  toggleMarketingSignUpModal: (open) => dispatch(toggleMarketingSignUpModal(open)),
  dismissMissingTagsAlert: () => dispatch(dismissMissingTagsAlert()),
  dismissErrorAlert: () => dispatch(dismissErrorAlert())
})


export default connect(mapStateToProps, mapDispatchToProps)(LinkPreviewTool);
